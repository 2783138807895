import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
      
    fetchImages(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .post('/gallery/v1/image/getall',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateOrdering(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .put('/gallery/v1/image/updateOrdering',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    
    uploadimages(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
          .post('/gallery/v1/image/add/'+queryParams.id,queryParams.formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchImageDelete(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/gallery/v1/image/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    }
  }
}